import { combineReducers } from "@reduxjs/toolkit";
import alertSlice from "../Slices/alert.slice";
import cartSlice from "../Slices/cart.slice";
import loaderSlice from "../Slices/loader.slice";

export const rootReducer = combineReducers({
  [alertSlice.name]: alertSlice.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
});
