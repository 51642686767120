import { ThemeProvider } from "@mui/material";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { MUITheme } from "./Helpers/MUI";
import router from "./Routes";

function App() {
  // const state = useSelector((s: any) => s.alert)
  // logger.info('Env: ', process.env.REACT_APP_ENV)

  // alert(window.innerWidth);

  return (
    <>
      <ThemeProvider theme={MUITheme()}>
        <RouterProvider router={router} />
        {/* <MUIAlert state={state.alertProps} /> */}
      </ThemeProvider>
    </>
  );
}

export default React.memo(App);
