import { createTheme } from "@mui/material";

export const MUITheme = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#402080",
      },
      secondary: {
        main: "#383f43",
      },
      error: {
        main: "#D4351C",
      },
      warning: {
        main: "#cc8f00",
      },
      info: {
        main: "#ffffff",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => {
            switch (ownerState.color) {
              case "primary":
                return {
                  "&.Mui-disabled": {
                    background:
                      ownerState.variant === "outlined" ? "#FFFFF" : "#402080",
                    opacity: 0.45,
                    color:
                      ownerState.variant === "outlined" ? "#402080" : "#FFFFFF",
                    border: "1px solid #402080",
                  },
                };
              case "secondary":
                return {
                  "&.Mui-disabled": {
                    background:
                      ownerState.variant === "outlined" ? "#FFFFFF" : "#B1B4B6",
                    opacity: 0.45,
                    color:
                      ownerState.variant === "outlined" ? "#B1B4B6" : "#FFFFFF",
                    border: "1px solid #B1B4B6",
                  },
                };
              case "error":
                return {
                  "&.Mui-disabled": {
                    background:
                      ownerState.variant === "outlined" ? "#FFFFFF" : "#D4351C",
                    opacity: 0.45,
                    color:
                      ownerState.variant === "outlined" ? "#D4351C" : "#FFFFFF",
                    border: "1px solid #D4351C",
                  },
                };
              case "warning":
                return {
                  "&.MuiButton-root": {
                    color: "#fff",
                    "&.Mui-disabled": {
                      background:
                        ownerState.variant === "outlined"
                          ? "#FFFFFF"
                          : "#cc8f00",
                      opacity: 0.45,
                      color:
                        ownerState.variant === "outlined"
                          ? "#cc8f00"
                          : "#FFFFFF",
                      border: "1px solid #cc8f00",
                    },
                  },
                };
              case "info":
                return {
                  "&.MuiButton-root": {
                    color: "#000000",
                    background: "#FFFFFF",
                    "&.Mui-disabled": {
                      background:
                        ownerState.variant === "outlined"
                          ? "transparent"
                          : "#FFFFFF",
                      opacity: 0.45,
                      color:
                        ownerState.variant === "outlined"
                          ? "#FFFFFF"
                          : "#000000",
                      border: "1px solid #FFFFFF",
                    },
                  },
                };
              default:
                return;
            }
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              borderRadius: "12px",
              minWidth: "400px",
              maxWidth: "fit-content",
              maxHeight: "calc(100% - 60px)",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#ECE9F2",
              color: "#402080",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#402080",
              color: "#ffffff",
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            ul: {
              border: "1px solid gray",
              width: "fit-content",
              borderRadius: "6px",
              li: {
                boxSizing: "border-box",
                width: "42px",
                height: "42px",
                borderLeft: "1px solid gray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                button: {
                  width: "42px",
                  height: "42px",
                  border: "unset",
                  margin: "0px",
                  padding: "0px",
                },

                "&:first-of-type": {
                  borderLeft: "unset",
                },
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  });

  return theme;
};
