/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AlertType {
  alertProps?: [boolean, string, "success" | "info" | "warning" | "error"];
}

const initialState: AlertType = {
  alertProps: [false, "", "error"],
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<
        [boolean, string, "success" | "info" | "warning" | "error"]
      >
    ) => {
      state.alertProps = action.payload;
    },
  },
});
export const { showAlert } = alertSlice.actions;

export default alertSlice;
