/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  products: [],
  userId: "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart: (state, action: PayloadAction<any>) => {
      state.products = [];
    },
    setProducts: (state, action: PayloadAction<any>) => {
      state.products = action.payload || [];
    },
  },
});

export const { resetCart, setProducts } = cartSlice.actions;
export default cartSlice;

const cartSelector = (state: { cart: any }) => state.cart;

export const selectCartProducts = createSelector(
  cartSelector,
  (state) => state.products
);

export const selectCartUserId = createSelector(
  cartSelector,
  (state) => state.userId
);
