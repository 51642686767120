import { lazy, Suspense } from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import Loader from "../Components/Shared/Loader";
import { APP_ROUTES } from "../Constants";

const DefaultLayout = lazy(() => import("../Layouts/Default"));
const ComingSoonPage = lazy(() => import("../Pages/Coming-Soon"));
const LoginPage = lazy(() => import("../Pages/Login"));
const SignupPage = lazy(() => import("../Pages/Signup"));
const ErrorPage = lazy(() => import("../Pages/Error"));
const HomePage = lazy(() => import("../Pages/Home"));
const GiftHampersPage = lazy(() => import("../Pages/Gift-Hampers"));
const GiftHamperDetailsPage = lazy(
  () => import("../Pages/Gift-Hamper-Details")
);
const MakeYourOwnPage = lazy(() => import("../Pages/Make-Your-Own"));
const CartDetailsPage = lazy(() => import("../Pages/Cart"));

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <DefaultLayout />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: APP_ROUTES.COMING_SOON,
        element: (
          <Suspense fallback={<Loader />}>
            <ComingSoonPage />
          </Suspense>
        ),
      },
      {
        path: "/",
        element: (
          <Suspense fallback={<Loader />}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN,
        element: (
          <Suspense fallback={<Loader />}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP,
        element: (
          <Suspense fallback={<Loader />}>
            <SignupPage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.GIFT_HAMPERS,
        element: (
          <Suspense fallback={<Loader />}>
            <GiftHampersPage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.GIFT_HAMPER_ID,
        element: (
          <Suspense fallback={<Loader />}>
            <GiftHamperDetailsPage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.MAKE_YOUR_OWN,
        element: (
          <Suspense fallback={<Loader />}>
            <MakeYourOwnPage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.CART,
        element: (
          <Suspense fallback={<Loader />}>
            <CartDetailsPage />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROFILE,
        element: (
          <Suspense fallback={<Loader />}>
            <div>PROFILE</div>
          </Suspense>
        ),
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
