export const MOBILE_QUERY = "(max-width: 1024px)";
export const SLIDE_SHOW_INTERVAL = 5000;
export const RUPEE_SYMBOL = "₹";

export const APP_ROUTES = {
  COMING_SOON: "/coming-soon",
  LOGIN: "/login",
  SIGNUP: "/signup",
  MAKE_YOUR_OWN: "/make-your-own",
  GIFT_HAMPERS: "/gift-hampers",
  GIFT_HAMPER_ID: "/gift-hampers/:id",
  CART: "/cart",
  PLACE_ORDER: "/place-order",
  PROFILE: "/profile",
  ORDERS: "/orders",
  ORDER_DETAILS: "/order-details",
};

export const APP_API_ROUTES = {
  PING: `${process.env.REACT_APP_BASE_URL}/ping`,
  LOGIN: `${process.env.REACT_APP_BASE_URL}/login`,
  ADD_USER: `${process.env.REACT_APP_BASE_URL}/addUser`,
  GET_COVER_PRODUCTS: `${process.env.REACT_APP_BASE_URL}/getProductsCover`,
  GET_BANNERS: `${process.env.REACT_APP_BASE_URL}/listBanners`,
  GET_PRODUCTS: `${process.env.REACT_APP_BASE_URL}/listProducts`,
  GET_CART: `${process.env.REACT_APP_BASE_URL}/getCart`,
};

export const S3_IMAGES_URL = {
  LOGO: "https://cherish-moments.s3.ap-south-1.amazonaws.com/logo/Picsart_24-09-24_20-14-44-180.png",
  PROFILE:
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/icons/profile.png",
  CART: "https://cherish-moments.s3.ap-south-1.amazonaws.com/icons/cart.png",
  STUB_PRODUCT:
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/products/opened-gift-box-icon-600nw-509387272.jpg",
  BANNERS: [],
  OCCASIONS: [
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/occasions/oc_1.jpeg",
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/occasions/oc_2.jpeg",
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/occasions/oc_3.jpeg",
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/occasions/oc_4.jpeg",
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/occasions/oc_5.jpeg",
    "https://cherish-moments.s3.ap-south-1.amazonaws.com/occasions/oc_6.jpeg",
  ],
};

export const VALIDATION_RULES: any = {
  fullName: {
    required: "Name is required.",
  },
  whatsapp: {
    required: "Whatsapp number is required.",
    pattern: {
      value: /^[0-9]{10}$/,
      message: "Mobile number must be 10 digits.",
    },
  },
  password: {
    required: "Password is required.",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long.",
    },
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      message:
        "Password must contain uppercase, lowercase, number, and special character @$!%*?&",
    },
  },
  confirmPassword: (getValues: any) => ({
    required: "Please confirm your password",
    validate: (value: any) =>
      value === getValues("password") || "Passwords do not match",
  }),
  addl1: {
    required: "Address line is required",
  },
} as any;

export const OCCASIONS_LIST = [
  {
    key: "birthday",
    value: "Birthday",
  },
  {
    key: "birthday",
    value: "Birthday",
  },
  {
    key: "birthday",
    value: "Birthday",
  },
];

export const LOCATIONS_LIST = [
  {
    key: "delhi",
    value: "Delhi",
  },
  {
    key: "gurgaon",
    value: "Gurgaon",
  },
  {
    key: "noida",
    value: "Noida",
  },
];
