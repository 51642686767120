import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Store/Hooks";
import { selectLoader, setLoader } from "../../../Store/Slices/loader.slice";

const Loader = () => {
  const loading: boolean = useAppSelector(selectLoader);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoader(false));
  }, [location]);

  return loading ? (
    <div className="loader">
      <CircularProgress variant="indeterminate" />
    </div>
  ) : null;
};

export default Loader;
